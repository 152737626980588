<template>
  <ContentWrapper class="hero" fluid>
    <HeroCounter v-if="!mdAndUp && notNoonYet" @now-is-noon="noonHandler" />
    <HeroHint
      v-if="!mdAndUp && !notNoonYet"
      :hint-label="heroHintLabel"
      :hint-text="heroHintText"
      :hint-mobile-text="heroHintMobileText" />
    <div class="position-relative">
      <ClientOnly>
        <div
          :class="{
            bg__gradient_top: (!mdAndUp && mobileBackground === null) || (mdAndUp && bg === null),
            hero: true,
          }"
          :style="{
            backgroundImage: `url(https://epd.directus.app/assets/${mdAndUp ? bg : mobileBackground}?format=webp&quality=80)`,
          }" />
      </ClientOnly>
      <v-container class="pa-0 pt-lg-16 pb-lg-9 inside">
        <v-row class="h-50" justify="center" align-content="start">
          <v-col cols="12" class="pt-9 pt-md-6 pt-xl-10">
            <h1 class="text-center pb-3">
              {{ title1 }}{{ mdAndUp ? ` ${title2}` : '' }}
              <br v-if="!mdAndUp" />
              <span v-if="!mdAndUp">{{ title2 }}</span>
            </h1>
            <HeroForm />
          </v-col>
          <v-col cols="auto">
            <v-row>
              <v-col
                v-for="claim in claims"
                :key="claim"
                class="font-weight-bold py-2 claims text-left"
                cols="12"
                md="auto">
                <v-icon class="text-primary">custom:checklist</v-icon>
                <span class="pl-4 claim">{{ claim }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          v-if="mdAndUp"
          align="end"
          justify="start"
          class="h-25 desktop-landing pt-4"
          :class="{ safari: isSafari }">
          <v-col v-if="notNoonYet" cols="6" class="pb-8">
            <HeroCounter @now-is-noon="noonHandler" />
          </v-col>
          <v-col v-if="!notNoonYet" cols="6" class="pb-8">
            <HeroHint
              :hint-label="heroHintLabel"
              :hint-text="heroHintText"
              :hint-mobile-text="heroHintMobileText" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </ContentWrapper>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'

const { mdAndUp } = useDisplay()
const { createNewGermanDate } = useDateUtils()
const { isSafari } = useDevice()

const props = defineProps<{
  title1: string
  title2: string | null
  claims: string[]
  bg: string | null
  heroHintLabel: string | null
  heroHintText: string | null
  heroHintMobileText: string | null
  mobileBackground: string | null
}>()

const { title1, title2, mobileBackground, bg } = props

const notNoonYet = ref(createNewGermanDate().getHours() < 12)

const noonHandler = () => {
  notNoonYet.value = false
}
</script>

<style lang="scss" scoped>
.hero {
  background-size: 100% 100%;
  aspect-ratio: 128/71;
}
.inside {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
  top: 0;
  height: 100%;
  .counter-wrapper {
    height: 60%;
  }
}
@media (max-width: 420px) {
  .hero {
    min-height: 850px;
    aspect-ratio: 389/1025;
  }
  .inside {
    height: 132vh;
    max-height: 880px;
  }
}
@media (min-width: 421px) and (max-width: 959px) {
  .hero {
    min-height: 958px;
    aspect-ratio: 389/1025;
  }
  .inside {
    height: 100vh;
  }
}
@media (min-width: 960px) and (max-width: 1250px) {
  .hero {
    min-height: 600px;
    aspect-ratio: 128/71;
  }
  .inside {
    max-width: fit-content;
  }
  .claims,
  .counter-wrapper {
    transform: scale(0.9);
  }
}
.desktop-landing :deep(.hint-card) {
  position: relative;
  margin-right: 40% !important;
  width: max-content;
  height: max-content;

  @media (max-width: 1919px) {
    transform: scale(0.78);
  }
  @media (max-width: 1250px) {
    margin-right: 20% !important;
  }
}
.desktop-landing :deep(.counter-wrap) {
  opacity: 1;
  margin-left: 0 !important;

  @media (min-width: 1920px) {
    margin-left: 20% !important;
  }
  @media (max-width: 1919px) {
    transform: scale(0.8);
  }
}
.desktop-landing.safari :deep(.counter-wrap) {
  margin-left: -20px !important;
}
</style>
